import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "TedTalks for Veterans in Transition: body language",
  "author": "Purepost",
  "date": "2018-02-09T06:26:52.000Z",
  "categories": ["Job Seeking"],
  "slug": "ted-talks-for-veterans-in-transition-body-language",
  "draft": false,
  "meta_title": "TedTalks for Veterans in Transition: body language"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`TED has spreading ‘ideas worth sharing’ all over the world since 1984. As something that began as a one-time conference quickly became an annual conference in 1990. TED (Technology, Education, Design) has grown into a flourishing non-for profit organization, finding the cutting edge experts on important issues and ideas.`}</p>
    <p>{`In a widening global platform of internet virality, it’s often hard to know what is worth your time and what you should just pass by without a second thought. If you have not yet heard of Ted Talks – they are among the most influential dialogues and speeches making impacts on social relationships, educational approaches, technology advances, environmental resources, political endeavors; you name it, there is probably a Ted Talk on the topic.`}</p>
    <p>{`So how can Ted Talks help in your transition? We are excited to feature a Ted Talk every month that highlights a different component of the transition process, career development, or civilian workplace. Being aware of modern issues, innovative solutions, and breakthroughs in all areas of personal development can be a driving force in confidence, presentation, and a successful transition.`}</p>
    <p>{`The way we walk, the way we talk- It all matters. Have you ever thought much about your body language during an interview? The vibe you give off at a social networking event? How your eyes and audible sighs form the relationships and shape the responses others give you?`}</p>
    <p>{`Dr. Cuddy is a professor and social psychologist at Harvard Business School. Her research on non-verbal expressions of power has afforded some groundbreaking insights and explanations that are relevant in just about every facet of day-to-day life.`}</p>
    <p>{`Leaving the military and entering the civilian workforce will bring a new wave of new people and new experiences in various social situations. What better to know than how our body language affects others and how we can cue others into what we are saying non-verbally. Cuddy explains how her research has empirically verified that certain specific types of body language shape who we are and have the ability to influence positive outcomes for us. The base summary of her discourse at the Ted Global event in Edinburgh, Scotland, is that engaging in “power poses” or dominant postures for as little as 2-minutes a day can decrease your cortisol levels (the stress hormone), increase your testosterone levels, and increase your appetite for risk. Who doesn’t need a little less stress and a little more excitement in their lives? The most immediately obvious use of this model for veterans or service members transitioning out of the military, as well as military spouses seeking employment, is to engage in “power poses” to prepare for job interviews. Dr. Cuddy, along with Dana R. Carney and Andrew J. Yap, have confirmed that the employment of this technique definitively improves the performance of job seekers during interviews. Cuddy summarizes the key aspects of the research, “Our bodies change our minds, and our minds can change our behavior, and our behavior can change our outcomes.”`}</p>
    <p>{`If you only watch one Ted Talk, make it this one; the guidance and advice that Professor Cuddy offers have the ability to dramatically increase the likelihood of positive outcomes as you move forward in your career and personal life!`}</p>
    <p>{`~The Purepost Team`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      